import React, { useState } from 'react';
import { TextField } from '@mui/material';

const PhoneInput = ({ value, onChange, onValidationChange }) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [isTouched, setIsTouched] = useState(false); // Track if the user has interacted with the input

  const whiteColor = '#ffffff'; // White for input background

  const validatePhoneNumber = async () => {
    if (!value) {
      setError(false);
      setHelperText('');
      onValidationChange(true); // No error for empty value
      return;
    }

    let isValid = true;

    // Development mode validation using regex
    if (process.env.NODE_ENV === 'development') {
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      if (!phoneRegex.test(value)) {
        setError(true);
        setHelperText('Invalid phone number format');
        isValid = false;
      } else {
        setError(false);
        setHelperText('');
      }
      onValidationChange(isValid);
      return;
    }

    // Production mode validation using Twilio Lookup API
    try {
      const response = await fetch('/.netlify/functions/lookupPhone', {
        method: 'POST',
        body: JSON.stringify({ phone: value }),
      });

      const result = await response.json();

      if (result.success) {
        setError(false);
        setHelperText('');
      } else {
        setError(true);
        setHelperText(result.message);
        isValid = false;
      }
    } catch (err) {
      setError(true);
      setHelperText('Invalid phone number');
      isValid = false;
    }

    onValidationChange(isValid); // Pass validation state to parent
  };

  const handleBlur = () => {
    setIsTouched(true);
    validatePhoneNumber();
  };

  return (
    <TextField
      fullWidth
      label="What's your phone number?"  // Changed label to question
      variant="outlined"
      value={value}
      onChange={onChange}
      onBlur={handleBlur}  // Validate when the user finishes entering the phone number
      error={error}
      helperText={isTouched && error ? helperText : ''}  // Only show helper text if the input was touched and has an error
      required
      type="tel"
      sx={{ backgroundColor: whiteColor }}  // Set input background to white
    />
  );
};

export default PhoneInput;

