import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Alert, Box, Typography, FormControlLabel, Switch } from '@mui/material';
import PersonCard from './PersonCard';
import { fetchPeople } from './fetchPeople'; // Import the fetchPeople function

const PeopleList = ({ user }) => {
  const [people, setPeople] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showArchived, setShowArchived] = useState(false); // State to track the toggle
  const navigate = useNavigate();

  useEffect(() => {
    const loadPeople = async () => {
      try {
        const fetchedPeople = await fetchPeople(showArchived); // Use the refactored function
        setPeople(fetchedPeople);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch people.');
        setLoading(false);
        navigate('/login');
      }
    };

    if (user) {
      loadPeople();
      const intervalId = setInterval(loadPeople, 5000); // Fetch data every 5 seconds

      return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }
  }, [user, showArchived, navigate]); // Re-fetch when user or showArchived state changes

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box>
      {/* Toggle Switch to Show/Hide Archived People */}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <FormControlLabel
          control={
            <Switch
              checked={showArchived}
              onChange={() => setShowArchived(!showArchived)}
              color="primary"
            />
          }
          label="Show Archived"
        />
      </Box>

      {people.length > 0 ? (
        people.map((person) => (
          <PersonCard key={person.$id} person={person} />
        ))
      ) : (
        <Typography variant="body1" align="center">
          No people found.
        </Typography>
      )}
    </Box>
  );
};

export default PeopleList;
