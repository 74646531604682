import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PeopleList from './PeopleList';
import { AppBar, Toolbar, Typography, Button, Container, Box } from '@mui/material';
import { useUser } from './UserContext'

const Admin = () => {
  const user = useUser()
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [user, navigate]);


  return (
    <div>
      <AppBar position="sticky">  {/* Set position to sticky */}
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Admin Dashboard
          </Typography>
          <Box sx={{ flexGrow: 1 }} /> {/* Spacer to push the button to the right */}
          <Button color="inherit" onClick={async () => {
            user.logout()
            navigate('/login');
          }}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ marginTop: 4 }}>
        <PeopleList user={user} />
      </Container>
    </div>
  );
};

export default Admin;

