import { Client, Account, Databases} from 'appwrite';

export const client = new Client();

client
    .setEndpoint('https://db.eggies.app/v1')
    .setProject('eggcellent'); 

export const databases = new Databases(client)

export const account = new Account(client);
export { ID } from 'appwrite';

