import React from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const StatusBadge = ({ status, sx, onStatusChange, person }) => {
  const [open, setOpen] = React.useState(false);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'pending':
        return 'yellow';
      case 'ready':  // Change from 'waiting' to 'ready'
        return 'green';
      case 'seated':
        return 'blue';
      case 'archived':
        return 'grey';
      default:
        return 'grey.300';
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStatusChange = async (newStatus) => {
    onStatusChange(newStatus);


    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getStatusColor(status),
          color: 'black',
          padding: '10px',
          borderRadius: '4px',
          minWidth: '60px',
          minHeight: '24px',
          textTransform: 'uppercase',
          cursor: 'pointer',
          ...sx,
        }}
        onClick={handleClickOpen}
      >
        <Typography variant="body2">
          {status || 'Unknown'}
        </Typography>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change Status</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2}>
            <Button
              onClick={() => handleStatusChange('pending')}
              sx={{ backgroundColor: 'yellow', color: 'black' }}
              variant={status === 'pending' ? 'contained' : 'outlined'}
            >
              Pending
            </Button>
            <Button
              onClick={() => handleStatusChange('ready')}  // Update status to 'ready'
              sx={{ backgroundColor: 'green', color: 'white' }}
              variant={status === 'ready' ? 'contained' : 'outlined'}
            >
              Table Ready
            </Button>
            <Button
              onClick={() => handleStatusChange('seated')}
              sx={{ backgroundColor: 'blue', color: 'white' }}
              variant={status === 'seated' ? 'contained' : 'outlined'}
            >
              Guest Seated
            </Button>
            <Button
              onClick={() => handleStatusChange('archived')}
              sx={{ backgroundColor: 'grey', color: 'white' }}
              variant={status === 'archived' ? 'contained' : 'outlined'}
            >
              Archived
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StatusBadge;
