import { Client, Databases, Query } from 'appwrite';

export const fetchPeople = async (showArchived) => {
  // Initialize Appwrite client
  const client = new Client();
  client
    .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT)
    .setProject(process.env.REACT_APP_APPWRITE_PROJECT_ID);

  const databases = new Databases(client);

  try {
    // Get the start of the current day in ISO format
    const today = new Date();
    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate()).toISOString();

    // Construct the query based on the showArchived state and today's date
    const query = [
      Query.greaterThanEqual('$createdAt', startOfDay), // Only fetch documents created today
      ...(showArchived ? [] : [Query.notEqual('status', 'archived')]), // Optionally filter out archived people
    ];

    const response = await databases.listDocuments(
      process.env.REACT_APP_APPWRITE_DATABASE_ID,
      'people',
      query // Apply the constructed query
    );

    return response.documents; // Return the fetched documents
  } catch (error) {
    console.error('Error fetching people:', error);
    throw new Error('Failed to fetch people');
  }
};

