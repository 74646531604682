import React, { useState } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import PartyBadge from './PartyBadge';
import StatusBadge from './StatusBadge';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { formatDistanceToNow } from 'date-fns';
import { databases } from './appwrite';

const PersonCard = ({ person }) => {
  const [currentStatus, setCurrentStatus] = useState(person.status);

  // Updated message constant
  const READY_TABLE_MESSAGE = `Hi ${person.name}, your table is ready at Eggcellent Cafe! We can’t wait to welcome you. Please make your way to the host stand when you’re ready to be seated. Thank you for your patience, we hope you're hungry!`;

  const handleStatusChange = async (newStatus) => {
    // Prevent SMS from being sent multiple times
    if (newStatus === 'ready' && currentStatus === 'ready') {
      return;
    }
    setCurrentStatus(newStatus);

    try {
      await databases.updateDocument(
        process.env.REACT_APP_APPWRITE_DATABASE_ID,
        'people',
        person.$id,
        { status: newStatus }
      );
      console.log('Status updated successfully');
      
      // Send SMS if new status is 'ready' and not in development mode
      if (newStatus === 'ready' && process.env.NODE_ENV !== 'development') {
        try {
          const response = await fetch('/.netlify/functions/sendSms', {
            method: 'POST',
            body: JSON.stringify({
              phone: person.phone,
              message: READY_TABLE_MESSAGE,
            }),
          });

          if (!response.ok) {
            const errorText = await response.text();
            console.error('Failed to send SMS:', errorText);
          }
        } catch (error) {
          console.error('Error sending SMS:', error);
        }
      }
    } catch (error) {
      console.error('Failed to update status:', error);
      setCurrentStatus(person.status);
    }
  };

  return (
    <Card variant="outlined" sx={{ mb: 2, position: 'relative' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" gutterBottom>
            {person.name}
          </Typography>
          <PartyBadge partyNumber={person.party} />
        </Box>
        <Box display="flex" alignItems="center">
          <PhoneIcon sx={{ mr: 1, color: 'grey.600' }} />
          <Typography variant="body2" color="textSecondary">
            {person.phone}
          </Typography>
        </Box>
        <Box mt={2} display="flex" alignItems="center">
          <HourglassTopIcon sx={{ mr: 1, color: 'grey.600' }} />
          <Typography variant="body2" color="textSecondary">
            {formatDistanceToNow(new Date(person.$createdAt), { addSuffix: true })}
          </Typography>
        </Box>
        {person.note && (
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">
              <strong>Note:</strong> {person.note}
            </Typography>
          </Box>
        )}
        <StatusBadge status={currentStatus} onStatusChange={handleStatusChange} person={person} sx={{ position: 'absolute', bottom: 8, right: 8 }} />
      </CardContent>
    </Card>
  );
};

export default PersonCard;

