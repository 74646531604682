import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid, Alert } from '@mui/material';
import { Client, Databases, ID } from 'appwrite';
import PhoneInput from './PhoneInput'; // Import the new PhoneInput component
import EggcellentLogo from './EggcellentLogo.png'; // Import the logo

const Home = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [party, setParty] = useState('');
  const [note, setNote] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);  // New state for submission status
  const [isPhoneValid, setIsPhoneValid] = useState(true);  // Track phone validation state

  // Color constants
  const yellowBackground = '#fcd20b'; // Yellow for background
  const grayText = '#62727d';         // Gray for text
  const whiteColor = '#ffffff';       // White for input background

  // Set the background color of the entire body
  useEffect(() => {
    document.body.style.backgroundColor = yellowBackground;
    return () => {
      document.body.style.backgroundColor = ''; // Reset when component unmounts
    };
  }, []);

  const client = new Client();
  client
    .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT)
    .setProject(process.env.REACT_APP_APPWRITE_PROJECT_ID);

  const databases = new Databases(client);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Prevent submission if phone is invalid
    if (!isPhoneValid || isSubmitting) {
      setError('Please enter a valid phone number.');
      return;
    }

    setIsSubmitting(true);  // Disable further submissions

    try {
      await databases.createDocument(
        process.env.REACT_APP_APPWRITE_DATABASE_ID,
        'people',
        ID.unique(),
        {
          name,
          phone,
          party: parseInt(party, 10),
          note,
          status: 'pending',
        }
      );

      if (process.env.NODE_ENV !== 'development') {
        const message =  `Thank you ${name} for joining the waitlist at Eggcellent Cafe! We’ll notify you as soon as your table is ready. Your patience is greatly appreciated!`
      // Call Netlify function to send SMS
        const response = await fetch('/.netlify/functions/sendSms', {
          method: 'POST',
          body: JSON.stringify({ phone, message }),
        });
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Failed to send SMS:', errorText);
          throw new Error('Failed to send SMS');
        }
      }

      setName('');
      setPhone('');
      setParty('');
      setNote('');
      setSuccess(true);
      setTimeout(() => setSuccess(false), 5000);
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to submit. Please try again.');
    } finally {
      setIsSubmitting(false);  // Re-enable the button after the process is complete
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4, p: 3, backgroundColor: yellowBackground, borderRadius: '8px' }}>
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <img src={EggcellentLogo} alt="Eggcellent Logo" style={{ width: '250px', height: '250px' }} /> {/* Updated size */}
      </Box>
      <Typography variant="h4" gutterBottom align="center" sx={{ color: grayText }}>
        Enter your details to be placed on the waiting list!
      </Typography>
      {error && <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>}
      {success && (
        <Alert severity="success" onClose={() => setSuccess(false)}>
          Submission successful! Please check your phone for a confirmation message.
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="What's your name?"  // Changed label to question
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              sx={{ backgroundColor: whiteColor }} // Set input background to white
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneInput
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              onValidationChange={(isValid) => setIsPhoneValid(isValid)}  // Handle phone validation
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="How many people are in your party?"  // Changed label to question
              variant="outlined"
              value={party}
              onChange={(e) => setParty(e.target.value)}
              required
              type="number"
              sx={{ backgroundColor: whiteColor }} // Set input background to white
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Any special requests or notes?"  // Changed label to question
              variant="outlined"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              multiline
              rows={4}
              sx={{ backgroundColor: whiteColor }} // Set input background to white
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isSubmitting}  // Disable the button during submission
              sx={{ backgroundColor: grayText, color: whiteColor }} // Set button to gray with white text
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Home;

