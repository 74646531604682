import React from 'react';
import { Box, Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';  // Import the People icon

const PartyBadge = ({ partyNumber }) => {
  // Determine the color based on the party number
  let backgroundColor;
  if (partyNumber <= 2) {
    backgroundColor = 'blue';
  } else if (partyNumber <= 4) {
    backgroundColor = 'orange';
  } else {
    backgroundColor = 'red';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor,
        color: 'white',
        padding: '4px 8px',
        borderRadius: '4px',
        minWidth: '36px',
      }}
    >
      <PeopleIcon sx={{ mr: 1 }} />  {/* Icon with right margin */}
      <Typography variant="h6" fontWeight="bold">
        {partyNumber}
      </Typography>
    </Box>
  );
};

export default PartyBadge;

